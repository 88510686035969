@font-face {
  font-family: "gt";
  font-weight: normal;
  src: url("./fonts/GTWalsheimPro-Regular.ttf");
  font-display: block;
}
@font-face {
  font-family: "gt";
  font-weight: 500;
  src: url("./fonts/GTWalsheimPro-Medium.ttf");
  font-display: block;
}
