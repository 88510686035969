:root {
  --white: #fff;
  --near-black: #232323;
  --red: #ff0000;
}

body {
  background: #000;
  color: var(--white);
  margin: 0;
  font-family: gt, -apple-system, inter, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1,
h2,
h3 {
  font-weight: normal;
}

a {
  color: currentColor;
  text-decoration: none;
}

::selection {
  background: #292a2d;
}

::-webkit-scrollbar {
  width: 0.6em;
}

::-webkit-scrollbar-thumb {
  background-color: #555;
  outline: 1px solid #555;
}

@import "fonts";
