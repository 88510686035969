.bg-near-black {
  background: var(--near-black);
}
.cover {
  object-fit: cover;
  object-position: left 0;
}
.project-grid {
  gap: 56px;
  display: grid;
  grid-template-columns: 1fr;
}
.project-grid img {
  object-position: left 0;
  object-fit: cover;
}
.project-grid div:not(.project-grid__item) a {
  display: block;
  overflow: hidden;
  height: 100%;
}
.locked {
  right: 0;
  padding: 6px 12px;
  border-radius: 6px;
  background: rgb(255 0 0 / 30%);
  color: #ff0000;
  font-weight: 500;
  margin-top: -12px;
}
.h5 {
  height: 14rem;
}

@media screen and (min-width: 60em) {
  .h5 {
    height: 16rem;
  }
  .f2-l {
    font-size: 2rem;
  }
  .h5-l {
    /* overriding project grids */
    /* height: 18rem; */
  }
  .project-grid {
    grid-template-columns: repeat(3, 1fr);
    gap: 56px;
  }
}
@media screen and (max-width: 59.9em) {
  .project-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 32px;
  }
}
@media screen and (max-width: 29.9em) {
  .ph35 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .h25 {
    height: 2.5rem;
  }
  .project-grid {
    grid-template-columns: 1fr;
    gap: 40px;
  }
}
